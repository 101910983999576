import styled, { keyframes } from 'styled-components'
import Loader from 'react-loader-spinner'
import { solease_green } from '../../assets/Colors'

const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    z-index: 999;
    animation: ${fadeIn} 0.5s;
    background-color: rgba(0, 0, 0, 0.2);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const SpinnerOverlay = () => (
    <Wrapper>
        <Loader
            type='Rings'
            color={solease_green}
            height={100}
            width={100}
            timeout={0}
        />
    </Wrapper>
)

export default SpinnerOverlay
