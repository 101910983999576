import { EnergyOptionInterface } from './interfaces'
import { excludeZipcodes } from './static'

const getHost = () => window.location.host.replace('www.', '')

const applyInsulationMeasures = (
    options: EnergyOptionInterface[],
    constructionYear: number
) => {
    let insulationMeasures: string[] //  Glass, wall, roof, floor

    if (constructionYear < 1975) {
        insulationMeasures = [
            'insulation_type_only_glass',
            'insulation_type_not_insulated',
            'insulation_type_not_insulated',
            'insulation_type_not_insulated'
        ]
    } else if (constructionYear < 1983) {
        insulationMeasures = [
            'insulation_type_double_glazed_ground_floor',
            'insulation_type_moderately_insulated',
            'insulation_type_moderately_insulated',
            'insulation_type_not_insulated'
        ]
    } else if (constructionYear < 1992) {
        insulationMeasures = [
            'insulation_type_double_glazed_ground_floor',
            'insulation_type_moderately_insulated',
            'insulation_type_moderately_insulated',
            'insulation_type_moderately_insulated'
        ]
    } else if (constructionYear < 2000) {
        insulationMeasures = [
            'insulation_type_double_glazed_whole_house',
            'insulation_type_well_insulated',
            'insulation_type_well_insulated',
            'insulation_type_well_insulated'
        ]
    } else {
        insulationMeasures = [
            'insulation_type_hr++',
            'insulation_type_well_insulated',
            'insulation_type_well_insulated',
            'insulation_type_well_insulated'
        ]
    }

    return options.map((option: EnergyOptionInterface) => {
        const activeType =
            option.id === 'glass'
                ? insulationMeasures[0]
                : option.id === 'wall'
                  ? insulationMeasures[1]
                  : option.id === 'roof'
                    ? insulationMeasures[2]
                    : option.id === 'ground'
                      ? insulationMeasures[3]
                      : false

        return {
            ...option,
            ...(activeType ? { activeType } : {}),
            active:
                activeType === 'insulation_type_well_insulated' ||
                activeType === 'insulation_type_hr++'
        }
    })
}

const remapHouseType = (houseType: string | undefined) =>
    houseType === 'Rijwoning tussen'
        ? 'housetype_terraced_house'
        : houseType === 'Twee/één kapwoning'
          ? 'housetype_2_under_1_roof'
          : houseType === 'Twee-onder-een-kap / rijwoning hoek'
            ? 'housetype_corner_house'
            : houseType === 'Vrijstaande woning'
              ? 'housetype_detached_house'
              : houseType === 'Flat'
                ? 'housetype_appartment'
                : houseType === 'Niet wonen'
                  ? 'housetype_no_home'
                  : houseType

const extractAdditionFromHouseNumber = (houseNumber: string) => {
    for (let i = 0; i < houseNumber.length; i++) {
        if (houseNumber[i].match(/[A-Z]/i)) {
            return [houseNumber.slice(0, i), houseNumber.slice(i)]
        }
    }

    return [houseNumber]
}

const zipcodeExcluded = (zip: string) =>
    !!excludeZipcodes.filter(excludeZipcode =>
        excludeZipcode.startsWith(zip.slice(0, 4))
    ).length

const randomAorB = () => {
    const options = ['A', 'B']
    const randomIndex = Math.floor(Math.random() * options.length)

    return options[randomIndex]
}

export {
    getHost,
    applyInsulationMeasures,
    remapHouseType,
    extractAdditionFromHouseNumber,
    zipcodeExcluded,
    randomAorB
}
