export const energyOptions = [
    {
        id: 'solar',
        description: 'service_solar_description',
        imgSrc: require('assets/icons/solar_panels.svg').default,
        img2Src: require('assets/icons/solar_panels_2.svg').default,
        type: 'energySolution',
        available: true,
        recommendedString: 'service_solar_recommended_string',
        notInterestedString: 'service_solar_notinterested_string'
    },
    {
        id: 'homebattery',
        description: 'service_homebattery_description',
        imgSrc: require('assets/icons/home_battery.svg').default,
        img2Src: require('assets/icons/home_battery_2.svg').default,
        type: 'energySolution',
        available: true,
        recommendedString: 'service_homebattery_recommended_string',
        notInterestedString: 'service_homebattery_notinterested_string'
    },
    {
        id: 'electric_car',
        description: 'service_electric_car_description',
        imgSrc: require('assets/icons/electric_car.svg').default,
        img2Src: require('assets/icons/electric_car.svg').default,
        type: 'energySolution',
        available: false
    },
    {
        id: 'charging_station',
        description: 'service_chargingstation_description',
        imgSrc: require('assets/icons/charging_station.svg').default,
        img2Src: require('assets/icons/charging_station_2.svg').default,
        type: 'energySolution',
        savings: '0.15',
        savingsDescription: 'service_chargingstation_savings_description',
        available: true,
        recommendedString: 'service_chargingstation_recommended_string',
        notInterestedString: 'service_chargingstation_notinterested_string'
    },
    {
        id: 'heatpump',
        description: 'service_heatpump_description',
        imgSrc: require('assets/icons/water_pump.svg').default,
        img2Src: require('assets/icons/water_pump_2.svg').default,
        type: 'energySolution',
        available: true,
        recommendedString: 'service_heatpump_recommended_string'
    },
    {
        id: 'glass',
        description: 'service_glass_description',
        adviceCardDescription: 'service_glass_advice_description',
        imgSrc: require('assets/icons/glass_insulation.svg').default,
        img2Src: require('assets/icons/glass_insulation_2.svg').default,
        type: 'energyLossPrevention',
        available: true,
        recommendedString: 'service_glass_recommended_string',
        notInterestedString: 'service_glass_notinterested_string',
        types: [
            'insulation_type_only_glass',
            'insulation_type_double_glazed_ground_floor',
            'insulation_type_double_glazed_whole_house',
            'insulation_type_hr++'
        ]
    },
    {
        id: 'wall',
        description: 'service_wall_description',
        imgSrc: require('assets/icons/cavity_insulation.svg').default,
        img2Src: require('assets/icons/cavity_insulation_2.svg').default,
        type: 'energyLossPrevention',
        available: true,
        recommendedString: 'service_wall_recommended_string',
        notInterestedString: 'service_wall_notinterested_string',
        types: [
            'insulation_type_not_insulated',
            'insulation_type_moderately_insulated',
            'insulation_type_well_insulated'
        ]
    },
    {
        id: 'roof',
        description: 'service_roof_description',
        imgSrc: require('assets/icons/roof_insulation.svg').default,
        img2Src: require('assets/icons/roof_insulation_2.svg').default,
        type: 'energyLossPrevention',
        available: true,
        recommendedString: 'service_roof_recommended_string',
        notInterestedString: 'service_roof_notinterested_string',
        types: [
            'insulation_type_not_insulated',
            'insulation_type_moderately_insulated',
            'insulation_type_well_insulated'
        ]
    },
    {
        id: 'ground',
        description: 'service_ground_description',
        imgSrc: require('assets/icons/floor_insulation.svg').default,
        img2Src: require('assets/icons/floor_insulation_2.svg').default,
        type: 'energyLossPrevention',
        available: true,
        recommendedString: 'service_ground_recommended_string',
        notInterestedString: 'service_ground_notinterested_string',
        types: [
            'insulation_type_not_insulated',
            'insulation_type_moderately_insulated',
            'insulation_type_well_insulated'
        ]
    }
]

export const familySizeOptions = [
    {
        label: 'label_1person',
        value: { kwh: 1100, gas: 1000, size: 1 },
        img: require('assets/icons/1_person.png')
    },
    {
        label: 'label_2person',
        value: { kwh: 2800, gas: 1200, size: 2 },
        img: require('assets/icons/2_persons.png')
    },
    {
        label: 'label_3person',
        value: { kwh: 3300, gas: 1500, size: 3 },
        img: require('assets/icons/3_persons.png')
    },
    {
        label: 'label_4person',
        value: { kwh: 3800, gas: 1700, size: 4 },
        img: require('assets/icons/4_persons.png')
    },
    {
        label: 'label_5person',
        value: { kwh: 4100, gas: 1900, size: 5 },
        img: require('assets/icons/5_persons.png')
    }
]

export const stepEnums = {
    1: 'Step 1 - Address',
    2: 'Step 2 - Flow menu',
    8: 'Step 2 Error - Address not found',
    3: 'Step 3 - Address confirmation',
    4: 'Step 4 - Consumption/Insulation menu',
    5: 'Step 5 - Personal Advice',
    6: 'Step 6 - Advice details',
    7: 'Step 7 - Offer sent final step',
    9: 'Step 4,5 - Owned services',
    10: 'DCT Step 1 - Address confirmation',
    11: 'DCT Step 2 - Consumption',
    12: 'DCT Step 3 - Results',
    19: 'DCT Step 3 Error - Results Error',
    13: 'DCT Step 4 - Results confirmation',
    15: 'DCT Step 3B - Manual Offer',
    16: 'DCT Step 5 - Offer sent final step'
}

export const excludeZipcodes = [
    '1795',
    '1796',
    '1797',
    '8899',
    '8890',
    '8891',
    '8892',
    '8893',
    '8894',
    '8895',
    '8896',
    '8897',
    '8880',
    '8881',
    '8882',
    '8883',
    '8884',
    '8885',
    '9160',
    '9161',
    '9162',
    '9163',
    '9164',
    '9166'
]

export const houseTypeOptions = [
    {
        label: 'housetype_terraced_house',
        value: 'terraced_house',
        gasRatio: 0.61,
        electricityRatio: 0.7
    },
    {
        label: 'housetype_corner_house',
        value: 'corner_house',
        gas_ratio: 1.02,
        electricity_ratio: 1.02
    },
    {
        label: 'housetype_2_under_1_roof',
        value: 'under_1_roof',
        gas_ratio: 1.2,
        electricity_ratio: 1.15
    },
    {
        label: 'housetype_detached_house',
        value: 'detached_house',
        gas_ratio: 1.56,
        electricity_ratio: 1.43
    },
    {
        label: 'housetype_appartment',
        value: 'appartment',
        gas_ratio: 0.61,
        electricity_ratio: 0.7
    },
    {
        label: 'housetype_upstairs_appartment',
        value: 'upstairs_appartment',
        gas_ratio: 1,
        electricity_ratio: 1
    },
    {
        label: 'housetype_downstairs_appartment',
        value: 'downstairs_appartment',
        gas_ratio: 1,
        electricity_ratio: 1
    }
]
