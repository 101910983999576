import styled from 'styled-components'
import { useContext } from 'react'
import { LanguageContext } from '../../utils/Context/LanguageContext'

const Wrapper = styled.div`
    margin-left: auto;
    background-color: #e3dfd9;
    height: 25px;
    width: 61px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;

    img {
        margin-right: 5px;
    }

    @media (max-width: 700px) {
        margin-right: 20px;
    }
`

const globeIcon = require('../../assets/icons/globe.svg').default

const LanguageButton = () => {
    const { switchLanguage, current } = useContext(LanguageContext)

    return (
        <Wrapper onClick={() => switchLanguage(current === 'en' ? 'nl' : 'en')}>
            <img src={globeIcon} alt='' />
            {current === 'en' ? 'NL' : 'EN'}
        </Wrapper>
    )
}

export default LanguageButton
