import 'assets/styles/global.css'
import { MainFrame } from './UI/components/MainFrame'
import HouseScan from './UI/pages/HouseScan'
import StepperContextProvider from './utils/Context/StepperContext'
import SharedDataContextProvider from './utils/Context/SharedDataContext'
import LanguageContextProvider from './utils/Context/LanguageContext'
import React from 'react'
import SettingsContextProvider, {
    SettingsInterface
} from './utils/Context/SettingsContext'

const App = (props: SettingsInterface) => (
    <div className='app'>
        <SettingsContextProvider {...props}>
            <StepperContextProvider>
                <LanguageContextProvider>
                    <MainFrame>
                        <SharedDataContextProvider>
                            <HouseScan />
                        </SharedDataContextProvider>
                    </MainFrame>
                </LanguageContextProvider>
            </StepperContextProvider>
        </SettingsContextProvider>
    </div>
)

export { App }
