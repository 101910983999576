import Axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL

const getRequest = (url: string, params: any, onSuccess: any, onError: any) => {
    Axios.get(apiUrl + url, { params })
        .then(res => {
            onSuccess(res.data, res.status)
        })
        .catch(error => onError(error))
}

const putRequest = (url: string, params: any, onSuccess: any, onError: any) => {
    Axios.put(apiUrl + url, { params })
        .then(res => {
            onSuccess(res.data, res.status)
        })
        .catch(error => onError(error))
}

const postRequest = (url: string, body: any, onSuccess: any, onError: any) => {
    Axios.post(apiUrl + url, body)
        .then(res => {
            onSuccess(res.data)
        })
        .catch(error => onError(error))
}

export { getRequest, postRequest, putRequest }
