import React, { useState } from 'react'
import { EnergyInfoInterface, PersonalInfoInterface } from '../interfaces'

interface SharedDataInterface {
    personalInfo: PersonalInfoInterface | undefined
    energyInfo: EnergyInfoInterface | undefined
}

interface SharedDataContextInterface {
    data: SharedDataInterface

    setPersonalInfo(personalInfo: PersonalInfoInterface): void

    setEnergyInfo(energyInfo: Partial<EnergyInfoInterface>): void
}

export const SharedDataContext = React.createContext(
    {} as SharedDataContextInterface
)

const SharedDataContextProvider = (props: { children: React.ReactNode }) => {
    const [data, setData] = useState<SharedDataInterface>({
        personalInfo: undefined,
        energyInfo: undefined
    })

    const setPersonalInfo = (personalInfo: PersonalInfoInterface) =>
        setData({
            ...data,
            personalInfo: { ...data.personalInfo, ...personalInfo }
        })
    const setEnergyInfo = (energyInfo: EnergyInfoInterface) =>
        setData({
            ...data,
            energyInfo: { ...data.energyInfo, ...energyInfo }
        })

    return (
        <SharedDataContext.Provider
            value={{ data, setPersonalInfo, setEnergyInfo }}
        >
            {props.children}
        </SharedDataContext.Provider>
    )
}

export default SharedDataContextProvider
