import styled from 'styled-components'
import { useContext } from 'react'
import { LanguageContext } from '../../utils/Context/LanguageContext'

const Wrapper = styled.div`
    position: absolute;
    top: 25px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    background: #ffffff;
    display: flex;
    align-items: center;
    margin: auto;
    height: 80px;
    width: 400px;
    justify-content: center;
    border: 1px solid #ced4da;
    border-radius: 10px;
    color: red;
    font-size: 14px;
    z-index: 99;
    transition:
        opacity 0.5s,
        top 0.5s;
    opacity: 0;
    padding: 0 20px;

    &.fade-enter {
        opacity: 0;
        top: -20px;
    }

    &.fade-enter-active {
        opacity: 1;
        top: 25px;
    }

    &.fade-exit {
        opacity: 0;
        top: -20px;
    }

    @media (max-width: 600px) {
        position: fixed;
        width: 90%;
    }
`
const ErrorPopup = ({ errorId }: { errorId?: string }) => {
    const { strings } = useContext(LanguageContext)

    return (
        <Wrapper>
            {errorId ? strings[errorId] : strings.error_popup_connection}
        </Wrapper>
    )
}

export { ErrorPopup }
