import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'
import React, {
    createContext,
    Suspense,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react'
import SpinnerOverlay from './SpinnerOverlay'
import { StepperContext } from '../../utils/Context/StepperContext'
import { CSSTransition } from 'react-transition-group'
import { ErrorPopup } from './ErrorPopup'

const MainWrapper = styled(AnimateHeight)`
    position: relative;
    margin: auto;
    max-width: 560px;
    min-height: 438px;
    box-sizing: content-box;
    padding: 60px;
    background: white;
    border-radius: 50px;
    box-shadow: 0 6px 4px 0 rgba(0, 0, 0, 0.25);
    text-align: center;
    overflow: hidden;
    z-index: 1;
    font-family: var(--font-family), sans-serif;
    color: var(--text-color);

    @media (max-width: 680px) {
        padding: 20px 17px;
        padding-bottom: 60px;
    }

    @media (max-width: 400px) {
        padding: 20px 10px;
        padding-bottom: 30px;
    }
`

interface MainFrameContextInterface {
    autoResize(scrollIntoView?: boolean, addedHeight?: number): void
    triggerErrorPopup(errorId?: string): void
}

const MainFrameContext = createContext({} as MainFrameContextInterface)

const MainFrame = (props: { children: React.ReactNode }) => {
    const [animHeight, setAnimHeight] = useState<number | string>('auto')
    const [errorPopup, setErrorPopup] = useState<{
        active: boolean
        errorId?: string
    }>({ active: false })

    const { step } = useContext(StepperContext)

    const autoResize = useCallback(
        (scrollIntoView?: boolean, addedHeight?: number) => {
            const wrapper = document.getElementById('housescan-main-wrapper')
            if (wrapper) {
                const wrapperDiv = wrapper.children[0]
                const calculatedHeight =
                    wrapperDiv.clientHeight + (addedHeight || 0)
                setAnimHeight(calculatedHeight || 'auto')
                if (scrollIntoView) {
                    const bodyRect = document.body.getBoundingClientRect().top
                    const elementRect = wrapper.getBoundingClientRect().top
                    const elementPosition = elementRect - bodyRect
                    const offsetPosition = elementPosition - 70
                    window.scrollTo({ top: offsetPosition, behavior: 'smooth' })
                }
            }
        },
        []
    )

    const triggerErrorPopup = useCallback(
        (errorId?: string) => setErrorPopup({ active: true, errorId }),
        []
    )

    useEffect(() => {
        setTimeout(() => autoResize(step !== 1), 50)
    }, [step, autoResize])

    useEffect(() => {
        errorPopup.active &&
            setTimeout(
                () => setErrorPopup({ ...errorPopup, active: false }),
                3000
            )
    }, [errorPopup])

    return (
        <MainWrapper
            id='housescan-main-wrapper'
            height={animHeight}
            duration={500}
        >
            <MainFrameContext.Provider
                value={{ autoResize, triggerErrorPopup }}
            >
                <CSSTransition
                    in={errorPopup.active}
                    timeout={3000}
                    classNames='fade'
                    mountOnEnter={true}
                    unmountOnExit={true}
                >
                    <ErrorPopup errorId={errorPopup.errorId} />
                </CSSTransition>
                <Suspense fallback={<SpinnerOverlay />}>
                    {props.children}
                </Suspense>
            </MainFrameContext.Provider>
        </MainWrapper>
    )
}

export { MainFrame, MainFrameContext }
