import ReactDOM from 'react-dom/client'
import { App } from './App'
import { SettingsInterface } from './utils/Context/SettingsContext'

// @ts-ignore
window.renderHouseScan = (config: { rootId: string } & SettingsInterface) => {
    const { rootId, ...settings } = config

    const root = ReactDOM.createRoot(
        document.getElementById(rootId) as HTMLElement
    )
    root.render(<App {...settings} />)
}

if (
    process.env.REACT_APP_RENDER_ON?.split(' ').includes(
        window.location.hostname
    )
) {
    // @ts-ignore
    window.renderHouseScan({ rootId: 'root' })
}
