import styled from 'styled-components'
import { solease_green, solease_misty_dove } from '../../assets/Colors'

const Wrapper = styled.div`
    position: relative;
    max-width: 368px;
    margin: auto;
    height: 21px;
    margin-bottom: 45px;
    background: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid ${solease_misty_dove};

    div {
        position: absolute;
        left: 0;
        background-color: ${solease_green};
        transition: width 1s;
        width: 50%;
        height: 100%;
    }

    p {
        z-index: 1;
        margin: 0 !important;
        @media (max-width: 600px) {
            font-size: 14px;
        }
    }
`

const ProgressBar = (props: { step: number }) => {
    const { step } = props

    const percentageVals = {
        1: 0,
        2: 20,
        3: 25,
        4: 50,
        9: 75,
        5: 100,
        6: 100,
        7: 100,
        8: 0,
        10: 33,
        11: 40,
        12: 60,
        13: 80,
        19: 50,
        15: 66,
        16: 100
    }

    const percentage = percentageVals[step as keyof typeof percentageVals]
    const stepExcluded =
        props.step === 1 || props.step === 6 || props.step === 7

    if (stepExcluded) return <></>
    else
        return (
            <Wrapper>
                <div
                    style={{ width: `${percentage === 50 ? 55 : percentage}%` }}
                />
                <p style={{ color: percentage > 49 ? 'white' : 'inherit' }}>
                    {percentage}%
                </p>
            </Wrapper>
        )
}

export default ProgressBar
