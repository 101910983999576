import { useCallback, useContext, useEffect, useState } from 'react'
import { SettingsContext } from '../Context/SettingsContext'
import {
    EnergyInfoInterface,
    EnergyOptionInterface,
    HouseTypeInterface,
    InsulationDataInterface,
    InsulationResponseInterface,
    PersonalInfoInterface,
    ProxyAddressInterface,
    ProxyResultsInterface
} from '../interfaces'
import { energyOptions as staticEnergyOptions } from '../static'
import { StepperContext } from '../Context/StepperContext'
import { MainFrameContext } from '../../UI/components/MainFrame'
import { SharedDataContext } from '../Context/SharedDataContext'
import TagManager from 'react-gtm-module'
import Cookies from 'js-cookie'
import {
    applyInsulationMeasures,
    extractAdditionFromHouseNumber,
    zipcodeExcluded
} from '../helperFunctions'
import { getRequest, postRequest, putRequest } from '../Api'
import { AxiosError } from 'axios'

const useAppController = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const initialPostcode = queryParams.get('postcode')
    const initialAddress = queryParams.get('housenumber')

    const options = useContext(SettingsContext)

    const [inputAddress, setInputAddress] = useState<{
        address: string
        postCode: string
    }>({
        address: initialAddress || '',
        postCode: initialPostcode || ''
    })
    const [addressString, setAddressString] = useState('')
    const [coordinates, setCoordinates] = useState({ lat: -1, lng: -1 })
    const [energyOptions, setEnergyOptions] =
        useState<EnergyOptionInterface[]>(staticEnergyOptions)
    const [pending, setPending] = useState(false)
    const [insulationData, setInsulationData] =
        useState<InsulationDataInterface>()
    const [leadsource, setLeadsource] = useState(String(options.leadsource))

    const [proxyAddressRes, setProxyAddressRes] =
        useState<ProxyAddressInterface>()
    const [consumption, setConsumption] = useState({ kwh: 0, gas: 0 })

    const { dispatchStep, flow, isUpdateRequest, dataLayerPush } =
        useContext(StepperContext)
    const { triggerErrorPopup } = useContext(MainFrameContext)

    const {
        setPersonalInfo,
        setEnergyInfo,
        data: { energyInfo }
    } = useContext(SharedDataContext)

    const { heatpumpOfferUrl } = useContext(SettingsContext)

    useEffect(() => {
        const gtmId = process.env.REACT_APP_GTM_ID
        const tagManagerArgs = {
            gtmId: gtmId || ''
        }
        TagManager.initialize(tagManagerArgs)
        checkAndRedirectToStep6() // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (flow === 'insulation') {
            setEnergyOptions(() =>
                staticEnergyOptions.filter(
                    option => option.type === 'energyLossPrevention'
                )
            )
        } else if (flow === 'heatpump') {
            setEnergyOptions(
                staticEnergyOptions.filter(option => option.id === 'heatpump')
            )
        } else if (flow === 'completeAdvice') {
            let energyOptionsBuffer = staticEnergyOptions

            // Make charging station and battery unavailable for Volksbank brand
            if (options.isVolksbankBrand) {
                energyOptionsBuffer = staticEnergyOptions.map(option => {
                    if (
                        option.id === 'homebattery' ||
                        option.id === 'charging_station'
                    ) {
                        return {
                            ...option,
                            available: false
                        }
                    } else return option
                })
            }
            setEnergyOptions(energyOptionsBuffer)
        }
    }, [flow, options])

    useEffect(() => {
        const gfMedium = Cookies.get('gf_medium_parameter')
        gfMedium && setLeadsource(gfMedium)
    }, [options.leadsource])

    const checkAndRedirectToStep6 = () => {
        const firstname = queryParams.get('firstname')
        const lastname = queryParams.get('lastname')
        const email = queryParams.get('email')
        const zip = queryParams.get('zip')
        const houseNumber = queryParams.get('housenumber')
        if (firstname && lastname && email && zip && houseNumber) {
            setPersonalInfo({ firstname, lastname, email })
            setInputAddress({
                address: houseNumber,
                postCode: zip
            })
            dispatchStep('redirect_personal_info')
        }
    }

    const setLocationData = useCallback(
        (address: string, postCode: string) => {
            setPending(true)
            setInputAddress({ address, postCode })
            const [houseNumber, addition] =
                extractAdditionFromHouseNumber(address)

            if (zipcodeExcluded(postCode)) {
                setPending(false)
                triggerErrorPopup('error_popup_region')

                return
            }

            getRequest(
                '/housescan/insulation/address',
                {
                    postcode: postCode,
                    number: houseNumber,
                    addition
                },
                (data: InsulationResponseInterface) => {
                    const { lat, lon, address, ...insulationData } = data
                    setInsulationData(insulationData)
                    setCoordinates({
                        lat,
                        lng: lon
                    })
                    setAddressString(address)
                    dispatchStep('next')
                    setPending(false)
                },
                (err: AxiosError) => {
                    if (err.response?.status === 404) {
                        setAddressString(`${postCode}, ${address}`)
                        dispatchStep('addressError')
                    } else triggerErrorPopup()
                    setPending(false)
                }
            )
        },
        [dispatchStep, triggerErrorPopup]
    )

    useEffect(() => {
        if (initialPostcode && initialAddress) {
            setLocationData(initialAddress, initialPostcode.toUpperCase())
        }
    }, [initialAddress, initialPostcode, setLocationData])

    const setProxyAddress = () => {
        setPending(true)
        const params = {
            postcode: inputAddress.postCode,
            number: inputAddress.address,
            leadsource
        }
        getRequest(
            '/housescan/roof/address',
            params,
            (response: ProxyAddressInterface) => {
                setProxyAddressRes(response)
                dispatchStep('next')
                setPending(false)
            },
            () => {
                setPending(false)
                triggerErrorPopup()
            }
        )
    }

    const getSavings = (options: EnergyOptionInterface[]) => {
        const params = {
            sqm_roofs: insulationData?.sqm_roofs,
            sqm_ground: insulationData?.sqm_ground,
            sqm_walls: insulationData?.sqm_walls,
            sqm_glass: insulationData?.sqm_glass,
            kwh: consumption.kwh,
            gas: consumption.gas
        }

        interface SavingInterface {
            savings: string
            costs: string
            subsidy: string
            co2Savings: string
        }

        interface SavingsResponseInterface {
            roof: SavingInterface
            wall: SavingInterface
            ground: SavingInterface
            glass: SavingInterface
            solar: SavingInterface
        }

        getRequest(
            '/housescan/savings',
            params,
            (data: SavingsResponseInterface) => {
                //  TODO: there has to be a better solution for mapping this
                const optionsBuffer = options.map(option => {
                    if (option.id && Object.keys(data).includes(option.id)) {
                        const savingsObj = Object.entries(data).filter(
                            ([key]) => key === option.id
                        )[0][1]

                        return {
                            ...option,
                            ...savingsObj
                        }
                    } else return option
                })
                setEnergyOptions(optionsBuffer)
                dispatchStep('next')
                setPending(false)
            },
            () => {
                triggerErrorPopup()
            }
        )
    }

    const getProxyResults = (repetition: number) => {
        if (repetition < 50) {
            setPending(true)
            const params = {
                transaction: proxyAddressRes?.transaction,
                wait: true,
                leadsource
            }
            getRequest(
                '/housescan/roof/results',
                params,
                (response: ProxyResultsInterface) => {
                    if (response.status === 'pending')
                        setTimeout(() => getProxyResults(repetition + 1), 500)
                    else if (response.status === 'ready') {
                        const optionsBuffer = energyOptions.map(option =>
                            option.id === 'solar'
                                ? {
                                      ...option,
                                      backgroundImage:
                                          'https://api.solease.nl' +
                                          response.background_map_url,
                                      panelsImage:
                                          'https://api.solease.nl' +
                                          response.imgsrc,
                                      panelsNumber: response.modules
                                  }
                                : option
                        )
                        response.modules < 4
                            ? console.log('todocase: less than 4 panels')
                            : console.log('panels ok')
                        getSavings(optionsBuffer)
                    }
                },
                () => {
                    setPending(false)
                    triggerErrorPopup()
                }
            )
        } else {
            //  T setStep(9)
        }
    }

    const setProxyConsumption = () => {
        if (consumption.kwh) {
            const params = `?e-consumption=${consumption.kwh}&transaction=${proxyAddressRes?.transaction}`
            putRequest(
                '/housescan/roof/consumption' + params,
                {},
                () => {
                    getProxyResults(1)
                },
                () => {
                    triggerErrorPopup()
                }
            )
        }
    }

    const step3Submit = (data: {
        sqm_ground: number
        sqm_roofs: number
        sqm_walls: number
        sqm_glass: number
        construction_year: number
        house_type: HouseTypeInterface
    }) => {
        setEnergyOptions(
            applyInsulationMeasures(energyOptions, data.construction_year)
        )
        setEnergyInfo({
            constructionYear: data.construction_year,
            houseType: data.house_type
        })
        setProxyAddress()
        if (insulationData) {
            const insulationDataBuffer: InsulationDataInterface = {
                ...insulationData
            }
            setInsulationData({ ...insulationDataBuffer, ...data })
        }
    }

    const step4Submit = (
        options: EnergyOptionInterface[],
        energyInfo: EnergyInfoInterface
    ) => {
        setConsumption({ kwh: energyInfo.consumption, gas: energyInfo.gas }) // TODO: remove consumption state and use energyInfo from Context instead
        setEnergyInfo(energyInfo)
        setEnergyOptions(options)
        if (
            energyInfo.consumption === consumption.kwh &&
            energyInfo.gas === consumption.gas
        )
            dispatchStep('next')
    }

    const Step4EnergySolutionSubmit = (options: EnergyOptionInterface[]) => {
        setEnergyOptions(options)
        dispatchStep('next')
    }

    useEffect(() => {
        consumption.kwh && setProxyConsumption() // eslint-disable-next-line
    }, [consumption])

    const submitHouseScanApplication = (
        personalInfo: PersonalInfoInterface,
        options: EnergyOptionInterface[],
        contractType: string
    ) => {
        setPending(true)
        const solar = options.filter(option => option.id === 'solar')[0]
        const heatpump = options.filter(option => option.id === 'heatpump')[0]
        const battery = options.filter(option => option.id === 'homebattery')[0]
        const chargingStation = options.filter(
            option => option.id === 'charging_station'
        )[0]

        const insulationOptions: { [key: string]: any } = {}

        options.forEach(option => {
            if (option.type === 'energyLossPrevention') {
                insulationOptions[`${option.id}`] = option.selected
                    ? {
                          sqm: insulationData
                              ? insulationData[
                                    `sqm_${option.id}` as keyof typeof insulationData
                                ]
                              : 0,
                          savings: option.savings,
                          co2Savings: option.co2Savings,
                          costs: option.costs,
                          subsidy: option.subsidy
                      }
                    : false
            }
        })

        const updateBody = {
            ...personalInfo,
            contractType: solar?.selected ? contractType : false,
            postcode: inputAddress.postCode,
            number: inputAddress.address,
            leadsource,
            solar: solar?.selected,
            insulation: {
                ground: !!insulationOptions.ground,
                wall: !!insulationOptions.wall,
                roof: !!insulationOptions.roof,
                glass: !!insulationOptions.glass
            }
        }

        const submitBody = {
            ...personalInfo,
            postcode: inputAddress.postCode,
            number: inputAddress.address,
            contractType: solar?.selected ? contractType : false,
            leadsource,
            consumption: consumption.kwh,
            solar: solar?.selected
                ? {
                      moduleUrl: solar.panelsImage?.split('/housescan')[1],
                      backgroundUrl:
                          solar.backgroundImage?.split('/housescan')[1],
                      moduleCount: solar.panelsNumber,
                      savings: solar.savings,
                      co2Savings: solar.co2Savings,
                      paybackPeriod: '4',
                      kwh: consumption.kwh,
                      transaction: proxyAddressRes?.transaction
                  }
                : false,
            heatpump: heatpump?.selected
                ? {
                      savings: heatpump.savings,
                      co2Savings: heatpump.co2Savings,
                      gas: energyInfo?.gas,
                      constructionYear: energyInfo?.constructionYear,
                      heatingType: energyInfo?.heatingType,
                      houseType: energyInfo?.houseType?.value,
                      heatpumpOfferUrl
                  }
                : false,
            insulation: insulationOptions,
            battery: battery?.selected,
            chargingStation: chargingStation?.selected
        }

        postRequest(
            isUpdateRequest ? '/housescan/submit/update' : '/housescan/submit/',
            isUpdateRequest ? updateBody : submitBody,
            () => {
                dispatchStep('next')
                setPending(false)
            },
            () => {
                setPending(false)
                triggerErrorPopup()
            }
        )
    }

    // TODO: merge all submit requests with some conditional logic
    const submitHeatpumpApplication = (
        personalInfo: PersonalInfoInterface,
        options: EnergyOptionInterface[]
    ) => {
        setPending(true)
        const heatpump = options.filter(option => option.id === 'heatpump')[0]

        if (!heatpump) {
            triggerErrorPopup()
            setPending(false)

            return
        }

        const submitBody = {
            ...personalInfo,
            postcode: inputAddress.postCode,
            number: inputAddress.address,
            leadsource,
            consumption: consumption.kwh,
            heatpump: heatpump
                ? {
                      savings: heatpump.savings,
                      co2Savings: heatpump.co2Savings,
                      gas: energyInfo?.gas,
                      constructionYear: energyInfo?.constructionYear,
                      heatingType: energyInfo?.heatingType,
                      houseType: energyInfo?.houseType?.value,
                      heatpumpOfferUrl
                  }
                : false
        }

        postRequest(
            '/housescan/submit/heatpump',
            submitBody,
            () => {
                dispatchStep('next')
                setPending(false)
            },
            () => {
                setPending(false)
                triggerErrorPopup()
            }
        )
    }

    const downloadPdf = (
        personalInfo: PersonalInfoInterface,
        options: EnergyOptionInterface[]
    ) => {
        const solar = options.filter(option => option.id === 'solar')[0]
        const heatpump = options.filter(option => option.id === 'heatpump')[0]
        const battery = options.filter(option => option.id === 'homebattery')[0]
        const chargingStation = options.filter(
            option => option.id === 'charging_station'
        )[0]

        const insulationOptions: { [key: string]: any } = {}
        options.forEach(option => {
            if (option.type === 'energyLossPrevention') {
                insulationOptions[`${option.id}`] = {
                    savings: option.savings,
                    co2Savings: option.co2Savings,
                    costs: option.costs,
                    subsidy: option.subsidy
                }
            }
        })

        const body = {
            ...personalInfo,
            email: personalInfo.email,
            postcode: inputAddress.postCode,
            number: inputAddress.address,
            leadsource,
            flow: flow === 'heatpump' ? 'heatpump' : 'main',
            solar: solar
                ? {
                      moduleUrl: solar.panelsImage?.split('/housescan')[1],
                      backgroundUrl:
                          solar.backgroundImage?.split('/housescan')[1],
                      moduleCount: solar.panelsNumber,
                      savings: solar.savings,
                      co2Savings: solar.co2Savings,
                      paybackPeriod: 4,
                      kwh: consumption.kwh
                  }
                : false,
            heatpump: heatpump
                ? {
                      savings: heatpump.savings,
                      co2Savings: heatpump.co2Savings,
                      gas: energyInfo?.gas,
                      constructionYear: energyInfo?.constructionYear,
                      heatingType: energyInfo?.heatingType,
                      houseType: energyInfo?.houseType?.value,
                      heatpumpOfferUrl
                  }
                : false,
            insulation: insulationOptions,
            battery: !!battery,
            chargingStation: !!chargingStation
        }

        postRequest(
            '/housescan/submit/download',
            body,
            () => {
                dataLayerPush('Download Advice', 'sent')
            },
            () => {
                triggerErrorPopup()
            }
        )
    }

    return {
        pending,
        inputAddress,
        setLocationData,
        coordinates,
        insulationData,
        step3Submit,
        energyOptions,
        setEnergyOptions,
        step4Submit,
        Step4EnergySolutionSubmit,
        downloadPdf,
        submitHouseScanApplication,
        submitHeatpumpApplication,
        addressString
    }
}

export default useAppController
