import translations from '../../assets/translations/translations.json'
import { createContext, ReactNode, useState } from 'react'

const parseStrings = (lang: string) =>
    Object.entries(translations).reduce(
        (acc, [key, value]) => {
            acc = { ...acc, [key]: value[lang as keyof typeof value] }

            return acc
        },
        {} as { [key: string]: string }
    )

interface LanguageContextInterface {
    current: string
    strings: { [key: string]: string }

    switchLanguage(keyword: string): void
}

export const LanguageContext = createContext({} as LanguageContextInterface)

const LanguageContextProvider = (props: { children: ReactNode }) => {
    const params = new URLSearchParams(window.location.search)
    const isEnglish = params.get('lang') === 'en'
    const initialLanguage = isEnglish ? 'en' : 'nl'

    const [language, setLanguage] = useState(initialLanguage)

    const languageStrings = parseStrings(language)

    const switchLanguage = (keyword: string) => setLanguage(keyword)

    return (
        <LanguageContext.Provider
            value={{
                strings: languageStrings,
                switchLanguage,
                current: language
            }}
        >
            {props.children}
        </LanguageContext.Provider>
    )
}

export default LanguageContextProvider
