import React, { lazy, useContext } from 'react'
import { StepperContext } from '../../utils/Context/StepperContext'
import ProgressBar from '../components/ProgressBar'
import useAppController from '../../utils/CustomHooks/useAppController'
import LanguageButton from '../components/LanguageButton'

const Step1 = lazy(() => import('../steps/Step1'))
const Step2 = lazy(() => import('../steps/Step2'))
const Step3 = lazy(() => import('../steps/Step3'))
const Step4 = lazy(() => import('../steps/Step4'))
const Step5 = lazy(() => import('../steps/Step5'))
const Step6 = lazy(() => import('../steps/Step6'))
const Step7 = lazy(() => import('../steps/Step7'))
const Step4EnergySolution = lazy(() => import('../steps/Step4EnergySolution'))
const AddressNotFound = lazy(() => import('../steps/AddressNotFound'))
const DakCheckTool = lazy(() => import('../pages/DakCheckTool'))
const SpinnerOverlay = lazy(() => import('../components/SpinnerOverlay'))

const HouseScan = () => {
    const {
        pending,
        inputAddress,
        setLocationData,
        coordinates,
        insulationData,
        step3Submit,
        energyOptions,
        setEnergyOptions,
        step4Submit,
        Step4EnergySolutionSubmit,
        downloadPdf,
        submitHouseScanApplication,
        submitHeatpumpApplication,
        addressString
    } = useAppController()

    const { step, flow } = useContext(StepperContext)

    return (
        <>
            {pending && <SpinnerOverlay />}

            <LanguageButton />

            <ProgressBar step={step} />

            {step === 1 ? (
                <Step1
                    initialAddress={inputAddress}
                    submitAddress={setLocationData}
                />
            ) : step === 2 ? (
                <Step2 />
            ) : step === 3 ? (
                <Step3
                    coordinates={coordinates}
                    insulationData={insulationData}
                    addressString={addressString}
                    onSubmit={step3Submit}
                />
            ) : step === 4 ? (
                <Step4 energyOptions={energyOptions} onSubmit={step4Submit} />
            ) : step === 5 ? (
                <Step5
                    energyOptions={energyOptions}
                    onSubmit={setEnergyOptions}
                    downloadAdvice={downloadPdf}
                />
            ) : step === 6 ? (
                <Step6
                    energyOptions={energyOptions}
                    onSubmit={
                        flow !== 'heatpump'
                            ? submitHouseScanApplication
                            : submitHeatpumpApplication
                    }
                />
            ) : step === 7 ? (
                <Step7 />
            ) : step === 8 ? (
                <AddressNotFound address={inputAddress} />
            ) : step === 9 ? (
                <Step4EnergySolution
                    energyOptions={energyOptions}
                    onSubmit={Step4EnergySolutionSubmit}
                />
            ) : (
                <DakCheckTool
                    sqm_roof={insulationData?.sqm_roofs || 0}
                    address={addressString}
                    coordinates={coordinates}
                    inputAddress={inputAddress}
                />
            )}
        </>
    )
}

export default HouseScan
